//Import de Librairies
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronRight} from "@fortawesome/free-solid-svg-icons"

//Import de composants
import Layout from "../decorate/Layout"

//Import de Fichiers
import menuLegal from '../../utils/Legals/menuLegal.json'
import "../../scss/components/legals/legalLayout.scss"
import {useToggle} from "../../utils/reusable/useHook"


function LineMenu({title, link, classIfActive}){
    return (
      <span className={classIfActive}>
        <a href={link}>{title}</a>
      </span>
    )
}

export default function LegalLayout({children, activeLink}){
  const [opened, setOpened] = useToggle(false)

  return(
    <Layout>
      <div className="legal-main">
        <div className={opened ? "opened legal-main__ctn-left" : "legal-main__ctn-left"}>
          <nav>
            <div>
              {
                menuLegal.fr.map(({ title, link },index) => {
                  return <LineMenu key={index} title={title} link={link} classIfActive={activeLink === link ? "isActive" : null} />
                })
              }
            </div>
          </nav>
          <button className={opened ? "opened btn-open" : "btn-open"} aria-label="toogle_menu_legals" onClick={setOpened}><FontAwesomeIcon icon={faChevronRight} /></button>
        </div>
        <div className="legal-main__ctn-right">
          {children}
        </div>
      </div>
    </Layout>

  )
}
