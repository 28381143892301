import React from "react"

//Import de composants
import LegalLayout from "../../components/legals/LegalLayout"
import {useToggle, useSearch} from "../../utils/reusable/useHook"

//Import de fichiers
import '../../scss/pages/legals/glossaire.scss'
import glossaire from '../../utils/Legals/glossaire.json'





/**
 * 
 * @param {String} terme le mot 
 * @param {String} def définition du mot
 * @returns l'élément jsx du composant
 */
function LineGlossaire({terme, def}){

  const [opened, setOpened] = useToggle(false)

  return(
    <div id={terme} className="item" onClick={setOpened} aria-hidden="true">
      <div className="terme"><span>{terme}</span></div>
      {opened ===true ? <div className="definition"><blockquote>{def}</blockquote></div> : null }
    </div>
      
  )
}



export default function Glossaire({location}){

  const [search, setSearch] = useSearch("")
  return(
    <LegalLayout activeLink={location.pathname}>
      <div className="glossaire">
        <div className="glossaire__head"><h1>Glossaire</h1><input type="text" className="recherche" name={search} placeholder="Rechercher un terme" onChange={setSearch}/></div>
        <div className="list-definition">
          {glossaire.map(({Terme,Definition}) => {
            if(Terme.toLowerCase().startsWith(search.toLowerCase()) || search==="")
            {
              return <LineGlossaire key={Terme} terme={Terme} def={Definition} />
            }
            return null
          })}
        </div>
      </div>
    </LegalLayout>
  )
}